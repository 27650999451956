.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}
.logo-image {
  height: 50px;
  padding-right: 10px;
}
.header-nav {
  background-color: #161618;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0px;
  top: 0px;
}
.navlinks-container {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;
}

/* gets rid of default css for navlinks so we can change them */
a:-webkit-any-link {
  text-decoration: none;
  color: darkgray;
}
/* hovers for navlinks turn text green */
a:-webkit-any-link:hover {
  text-decoration: none;
  color: white;
}

.auth-container {
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 30px;
  justify-content: space-between;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container > a {
  font-size: xx-large;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  top: -4px;
}
#root {
  padding-top: 42px;
}
.nav-buttons-container {
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-login-button:hover {
  cursor: pointer;
}
.nav-signup-button:hover {
  cursor: pointer;
}
.nav-buttons-container > div > a > button {
  border: none;
  border-radius: 5px 5px 5px 5px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.nav-buttons-container > div > a > button:hover {
  color: lightgray;
}

.logout-button {
  border: none;
  border-radius: 5px 5px 5px 5px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-right: 50px;
  margin-top: 5px;
}
.logout-button:hover{
  cursor: pointer;
  color: lightgray;
}
body {
  margin: 0;
}
