html {
  font-family: sans-serif;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #161618;
    color: white;
    text-align: center;
    height: 5%;
    display: flex;
    align-items: center;

  }
  .socials-title {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .footer-github-logo {
    background-color: black;
  }
.social-links > a > img {
    background-color: #202023;
    width: 20px;
    height: 20px;
}
  .social-links {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .landing-page-container {
    background: url('https://images.unsplash.com/photo-1549880181-56a44cf4a9a5?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8fA%3D%3D');
    background-repeat: no-repeat;
    background-size: 100%, 100%;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
  }

.landing-intro{
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 30px;
    padding-top: 285px;
    color: white;
    font-family: sans-serif;
}

.start-for-free-button {
    height: 64px;
    font-size: 24px;
    font-weight: 800;
    border-radius: 5px 5px 5px 5px;
    border: none;
    margin-top: 20px;
    background: white;
    color: black;
}

.start-for-free-button:hover {
    color: rgb(202, 184, 184);
    cursor: pointer;
}

.activity-container {
    width: 50%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
}
.email > i {
  color: #A9A9A9;
}
.email > i:hover {
  color: white;
}
.linkedin > i:hover {
  color: white;
}
.github > i:hover {
  color: white;
}
