#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(172, 167, 167, 0.5);
}

#modal-content {
  position: absolute;
  background-color:white;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 20px gray;
}
