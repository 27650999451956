.login-container {
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 185px;
    padding-bottom: 100px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    border: none;
    box-shadow: 10px 5px 5px rgb(101, 98, 98);
}
.login-errors {
    color: red;
    font-weight: bold;
}
.login-container > img {
    padding-top: 20px;
}
label {
    display: block;
    padding-top: 10px;
}
input {
    display: block;
    margin-top: 10px;
    height: 20px;
    border-radius: 5px 5px 5px 5px;
    border-width: 1px;
}
input:focus{
    outline: none;
    border-color: rgb(87, 87, 174);
}
.demo-button{
    margin-top: 30px;
    display: block;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.login-button{
    display: block;
    margin-top: 30px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
