.create-photo-button-container {
    background-color: #F3F5F6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
.you-page-nav {
    border-bottom: 1px solid lightgray;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.you-page-nav > div > a:hover {
    color: black;
}
.you-page-nav > div {
    height: 95%;
    display: flex;
    align-items: center;
}
.profile-info {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 300px;
    margin-top: 8px;
    background-image: url('https://combo.staticflickr.com/pw/images/coverphoto03_h.jpg.v3');
    background-repeat: no-repeat;
    background-size: 100% ;
    background-position: 50% 50%;
    padding-left: 250px;
    color: rgb(255, 255, 255);
    /* font-size: 36px; */
    font-weight: 300;
    font-family: Proxima Nova,helvetica neue,helvetica,arial,sans-serif;
    /* position: absolute; */
}

.all-photos-container {
    display: flex;
    background-color: #F3F5F6;
    min-height: calc(100vh - 516px);
    flex-wrap: wrap;
    padding-bottom: 140px;
}

.all-photos-container > div {
    margin-top: 20px;
    height: 320px;
    width: 320px;
    margin-left: 36px;
    margin-bottom: 40px;
}

.all-photos-container > div > img {
    height: 320px;
    width: 320px;
}
.all-photos-container > div > img:hover {
    cursor: pointer;
}
.create-photo-button {
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.create-photo-button:hover {
    color: black;
    cursor: pointer;
}
.photo-images-container {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 24px;
    right: 45px;
}
/* .photo-images-container > div > button {
    margin-top: 5px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
} */
/* .photo-images-container > div > button:hover {
    color: black;
} */
.profile-email{
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    z-index: 2;
    position: relative;
}
.profile-name {
    font-size: 36px;
    z-index: 2;
    position: relative;
}
.create-photo-container {
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 145px;
    padding-bottom: 100px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    border: none;
    box-shadow: 5px 5px 10px 30px  rgb(101, 98, 98);
}
.create-photo-button{
    margin-top: 20px;
}
.image-label {
    display: block;
    margin-top: 30px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.update-photo-form-container {
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 145px;
    padding-bottom: 100px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    border: none;
    box-shadow: 5px 5px 10px 30px  rgb(101, 98, 98);
}
.update-pic-button {
    display: block;
    margin-top: 30px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: #1396E7;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.update-pic-button:hover {
    color: black;
}
.delete-confirmation {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 145px;
    padding-bottom: 100px;
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    border: none;
    box-shadow: 5px 5px 10px 30px  rgb(101, 98, 98);
}
.yes-button {
    display: block;
    margin-top: 30px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.yes-button:hover {
    color: black;
}
.no-button {
    display: block;
    margin-top: 30px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    color: white;
    background-color: green;
    border: none;
    border-radius: 5px 5px 5px 5px;
}
.no-button:hover{
    color: black;
}
.photo-by-id-container {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 8px;
    padding-bottom: 350px;
    background-color: #212124;
    border: none;
    box-shadow: 5px 5px 10px 10px  rgb(101, 98, 98);
}
.photo-by-id-name {
    color: white
}
.back-to-photos-div > i {
    color: #F3F5F6;
}
.back-to-photos-div {
    color:#F3F5F6;
    float: left;
    /* width: 100%; */
    position: absolute;
    left: 15px;
    top: 40px;
}
.back-to-photos-div:hover {
    text-decoration: underline;
    cursor: pointer;
}
.photo-edit-container > i {
    color: white;
    padding-right: 20px;
}
.photo-edit-container > i:hover {
    color: green;
    padding-right: 20px;
    cursor: pointer;
}
.photo-delete-container > i {
    color: white;
}
.photo-delete-container > i:hover {
    color: red;
    cursor: pointer;
}
.photo-by-id-photo-container {
    position: relative;
    top: 110px;
}
.photo-info-container {
    display: flex;
    margin: auto;
    width: 50%;
    padding-top: 40px;
}
.left-photo-info-container {
    width: 500px;
    margin-right: 40px;
}
.right-photo-info-container {
    width: 500px;
    margin-left: 20px;
}
.bottom-section-container{
    width: 100%;
}
.photo-owner-name{
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 10px;
}
.photo-owner-name:hover{
    color: #006DAC;
    text-decoration: underline;
    cursor: pointer;
}
.photo-name {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 10px;
}
.photo-description {
    padding-bottom: 10px;
}
.photo-info {
    border-bottom: 1px solid #CFD6D9;
    margin-bottom: 20px;
}
.comment-owner{
    color: #006DAC;
}
.comment-owner:hover {
    text-decoration: underline;
    cursor: pointer;
}
.comment-top-container {
    display: flex;
    justify-content: space-between;
}
.comment-update {
    color: #999999;
    font-weight: bold;
    font-size: 20px;
}
.comment-update:hover {
    cursor: pointer;
}
.comment-date{
    color: #999999;
}
.comment{
    padding-bottom: 20px;
    /* overflow: sho; */
    word-break: break-word;
    position: relative;
}
.comment-body{
    font-size: 14px;
}
.new-comment-text {
    border: none;
    resize: none;
    width: 100%;
    height: 70px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(118,118,118);
}
.new-comment-text:focus{
    outline: none !important;
    border-color: #008DDF;
}
.new-comment-button{
    border: none;
    background-color: #128FDC;
    color: white;
    border-radius: 5px 5px 5px 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    float: right;
    height: 32px;
    min-width: 80px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
}
.new-comment-button:hover{
    background-color: #1C9BE9;
    cursor: pointer;
}
.like-container > i {
    color: white;
    padding-right: 20px;
}
.like-container > i:hover {
    color: green;
    padding-right: 20px;
    cursor: pointer;
}
.dislike-container > i {
    color: white;
    padding-right: 20px;
}
.dislike-container > i:hover {
    color: red;
    padding-right: 20px;
    cursor: pointer;
}
.likes-amount-title{
    color: #999999;
}
.comments-amount-title{
    color: #999999;
}
.right-photo-info-top-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #CFD6D9;
    min-height: 100px;
}
.test-div{
    right: -4px;
    top: -10px;
    position: absolute;
}
#comment-edit{
    color: #212124;
    padding-right: 10px;
}
#comment-edit:hover{
    color: green;
    cursor: pointer;
}
#comment-delete{
    color: #212124;
}
#comment-delete:hover{
    color: red;
    cursor: pointer;
}
.gradient-test{
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.03) 8%,rgba(0,0,0,.11) 21%,rgba(0,0,0,.61) 78%,rgba(0,0,0,.7) 95%,rgba(0,0,0,.7));
    z-index: 1;
    width: 100%;
    height: 15%;
    position: absolute;
    left: 0px;
}
